import React from "react";
import PropTypes from 'prop-types';

import LightboxColumn from "./lightbox-column";

import "../styles/image-columns.less";

const LightboxContainer = ({columns, className}) =>
{
    const imagesList = columns.map((column) => {
        return column.node.imagePath.childImageSharp.fluid.src;
    });
    if (!columns) { return null; }
    return <div className={"row justify-content-center image-columns "+className}>
        {columns.map((column, index) => {
            return <LightboxColumn key={index} link={column.node.link} images={imagesList} imageName={column.node.imageName} colSize={column.node.colSize} className={column.node.className} imageIndex={index} imageAlt={column.node.imageAlt} text={column.node.text} />
        })}
    </div>
}

LightboxContainer.propTypes = {
    columns: PropTypes.array.isRequired,
    className: PropTypes.string
}

export default LightboxContainer;
