import React from "react";
import { injectIntl } from "gatsby-plugin-intl";
import Image from './image';

const Column = ({intl, column}) => (
    <div className={"column col-lg-"+column.colSize + " col-md-4 " + column.className}>
        <div>
            <Image filename={column.imageName} alt={intl.formatMessage({id: column.imageAlt})} />
        </div>
        <p className="column-title">
            {intl.formatMessage({id: column.title})}
        </p>
        {column.subtitle && <p className="column-content">{intl.formatMessage({id: column.subtitle})}</p>}
    </div>
)

export default injectIntl(Column);
