import React from "react";
import loadable from '@loadable/component';

import Image from './image';
import { injectIntl } from "gatsby-plugin-intl"
import 'react-image-lightbox/style.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const Lightbox = loadable(() => import('react-image-lightbox'))

class LightboxColumn extends React.Component
{
    constructor(props)
    {
        super(props);
        //({colSize, className, imageIndex, text})
        this.state = {isOpen: false, imageIndex: this.props.imageIndex};
    }

    render()
    {
        return <div className={"column col-md-"+this.props.colSize + " " + this.props.className}>
            <div onClick={() => this.setState({ isOpen: true, imageIndex: this.props.imageIndex })}>
                <Image filename={this.props.imageName} alt={this.props.imageAlt} />
                <div className="overlay">
                    <FontAwesomeIcon icon={faSearch} />
                </div>
            </div>
            <p>
                {this.props.text}
            </p>
            {this.props.link && <a target="_blank" rel="noopener noreferrer" href={this.props.link}>{this.props.intl.formatMessage({id: "Visit"})}</a>}

            {this.state.isOpen && (
                <Lightbox
                    mainSrc={this.props.images[this.state.imageIndex]}
                    nextSrc={this.props.images[(this.state.imageIndex + 1) % this.props.images.length]}
                    prevSrc={this.props.images[(this.state.imageIndex + this.props.images.length - 1) % this.props.images.length]}
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                    this.setState({
                        imageIndex: (this.state.imageIndex + this.props.images.length - 1) % this.props.images.length,
                    })
                    }
                    onMoveNextRequest={() =>
                    this.setState({
                        imageIndex: (this.state.imageIndex + 1) % this.props.images.length,
                    })
                    }
                />
            )}
        </div>
    }
}

export default injectIntl(LightboxColumn);