import React from "react";
import PropTypes from 'prop-types';

import Column from "./column";

import "../styles/image-columns.less";

const ImageColumns = ({columns, className}) =>
{
    if (!columns) { return null; }
    return <div className={"row justify-content-center image-columns "+className}>
        {columns.map((column, index) => {
            return <Column key={index} column={column.node} />
        })}
    </div>
}

ImageColumns.propTypes = {
    columns: PropTypes.array.isRequired,
    className: PropTypes.string
}

export default ImageColumns;
